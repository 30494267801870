import React, { useState, useRef, useLayoutEffect } from "react";
import axios from "axios";
import { Button } from "components";
import "./phoneNumber.scss";
import IconCheckbox from "assets/images/icons/icon-checkbox-white.svg";

const PhoneNumber = ({ setData, setCurrentStep }) => {
	const [phone, setPhone] = useState('');
	const handleNext = () => {
		axios.post(`https://sales.alfainvest.uz:3000/send-sms`, {
			phone: phone
		}).then(e => {
			setCurrentStep((prevState) => prevState + 1)
			setData({
				phone: phone
			})
		})
	}

	const updateInputValue = (evt) => {
		setPhone(evt.target.value)
	}

	return (
		<div className="phoneNumber">
			<div className="phoneNumber__header">
				<h3 className="phoneNumber__header-title">Авторизация</h3>
				<p className="phoneNumber__header-subtitle">Создайте новый аккаунт или войдите в существующий</p>
			</div>
			<div className="phoneNumber__content">
				<div className="input-section">
					<label htmlFor="phonenumber-input">Контактный номер</label>
					<input onChange={updateInputValue} id="phonenumber-input" type="number" name="phonenumber" placeholder="+998 (" />
				</div>
				<div className="agree">
					<div className="checkbox" style={{ background: `url(${IconCheckbox})` }}>
						<input type="checkbox" id="agree" name="agree" />
						<label htmlFor="agree"></label>
					</div>
					<p className="agree__title">Согласен на обработку персональных данных</p>
				</div>
				<div className="phoneNumber__btn">
					<Button.Default
						title="Далее"
						onClick={handleNext}
						buttonType="button"
						type="default"
						size="large"
						rounded={true}
						hasIcon={false}
						hasIconArrow={false}
						borderType="half"
					/>
				</div>
			</div>
		</div>
	);
};

export default PhoneNumber;
