import DefaultSelect from "./DefaultSelect";
import MultiSelect from "./MultiSelect";
import MSelect from "./MSelect";

const Selects = {
	DefaultSelect,
	MultiSelect,
	MSelect
};

export default Selects;
