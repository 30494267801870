import React, { useState } from "react";
import { Popup } from "components";
import "./auth.scss";
import PhoneNumber from "./PhoneNumber";
import { useNavigate } from "react-router-dom";
import PhoneNumberVerification from "./PhoneNumberVerification";
import SignUp from "./SignUp";
import Password from "./Password";

const Auth = ({setShowAuthModal, redirect}) => {
	const [showModal, setShowModal] = useState(true);
	const [mode, setMode] = useState(1);
	const [data, setData] = useState({});

    const navigate = useNavigate();
	const handleCloseModal = () => {
		setShowModal(false);
		if(setShowAuthModal) {
			setShowAuthModal(false)
		}
		if(redirect) {
			navigate("/", {});
		}
	};
	const renderModal = (step, setCurrentMode) => {
		switch (step) {
			case 1:
				return <PhoneNumber setCurrentStep={setCurrentMode} setData={setData} />;
			case 2:
				return <PhoneNumberVerification setCurrentStep={setCurrentMode} data={data} setData={setData} handleCloseModal={handleCloseModal} />;
			default:
				return null;
		}
	};

	return (
		<div className="auth">
			<Popup.SimpleAnimatedModal opened={showModal} onClose={handleCloseModal}>
				{renderModal(mode, setMode)}
			</Popup.SimpleAnimatedModal>
		</div>
	);
};

export default Auth;
