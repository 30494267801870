import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { Icon } from "components";
import "./style.scss";

const DefaultButtonComponent = ({
	title,
	buttonType,
	type,
	borderType,
	size,
	width,
	onClick,
	className,
	rounded,
	loading,
	iconBefore,
	hasIcon,
	hasIconArrow,
	iconAfter,
	svgColor,
	prepend,
	append,
	tooltip,
	disabled,
	children,
	...otherProps
}) => {
	const classNames = cx("btn", size === "md" ? "" : `btn-${size}`, rounded && `btn-rounded-${borderType}`, type && `btn-${type}`, `btn-${width}`, className ? className : "");


	return (
		<button type={buttonType} className={classNames} {...otherProps} onClick={onClick} disabled={loading || disabled} title={tooltip}>
			{hasIcon && <Icon name={iconBefore} fill={svgColor} />}
			{prepend && prepend}
			{title ? <>{title}</> : <>{children}</>}{" "}
			{hasIconArrow && <Icon name={iconAfter} fill={svgColor} />}
			{append && append}
		</button>
	);
};

DefaultButtonComponent.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	onClick: PropTypes.func,
	rounded: PropTypes.bool,
	hasIconArrow: PropTypes.bool,
	hasIcon: PropTypes.bool,
	svgColor: PropTypes.oneOf(["white","black","#B3B3B3", "#0B0C0D"]),
	buttonType: PropTypes.oneOf(["reset", "button", "submit"]),
	type: PropTypes.oneOf(["default", "defaultBlack", "active", "black", "light", "disabled"]),
	size: PropTypes.oneOf(["extra-small", "small", "signIn", "notext", "medium", "normal", "large"]),
	borderType: PropTypes.oneOf([ "full","half","small"]),
	fill: PropTypes.string,
	width: PropTypes.string,
	iconAfter: PropTypes.oneOf(["arrowbig-right", "arrow-down", "reset", "basket", "deleteWhite"]),
	iconBefore: PropTypes.oneOf(["icon-rule", "delete", "user", "catalog", "basket", "favorite", "plus"])
};

DefaultButtonComponent.defaultProps = {
	hasIcon: true,
	hasIconArrow: true,
	rounded: false,
	buttonType: "button",
	type: "default",
	size: "large",
	iconBefore: "icon-rule",
	iconAfter: "arrowbig-right"
};

export default DefaultButtonComponent;
