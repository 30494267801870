import React from "react";
import { Button } from "components";
import "./advertisingCard.scss";

const AdvertisingCard = ({title, brend, price, AdImage}) => {
	return (
		<div className="advertisingCard">
			<img src={AdImage} alt="addImage" />
			<div className="advertisingCard__content">
				<h3 className="type">{title}</h3>
				<span className="brend">Бренд: {brend}</span>
				<div className="advertisingCard__bottom">
					<Button.Default
                     buttonType="button"
                     type="default"
                     hasIconArrow={true}
					 iconAfter="basket"
					 svgColor="white"
                     hasIcon={false}
                     size="medium" 
                     borderType="half" 
                     rounded={true} />
                     <div className="price">
                        <span>Стоимость </span>
                        <p>{price} UZS</p>
                     </div>
				</div>
			</div>
		</div>
	);
};

export default AdvertisingCard;
