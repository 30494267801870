import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import { PaginationItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

import { Button, Select } from "components";
import "./carInfo.scss";
import IconSearch from "assets/images/icons/icon-search.svg";
import IconDownWhite from "assets/images/icons/icon-arrow-down-white.svg";
import arrowLeft from "assets/images/icons/icon-arrowbig-left.svg";
import arrowRight from "assets/images/icons/icon-arrowbig-right.svg";
import IconChecked from "assets/images/icons/icon-check-el.svg";
import IconInsurance from "assets/images/icons/icon-insurance.svg";

const CarOptions = ["01 а 001 аа", "02 а 011 da", "01 b 010 db"];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 4,
	borderRadius: 8,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 8,
		backgroundColor: theme.palette.mode === "light" ? "#0047FF" : "#308fe8"
	}
}));

const CarInfo = () => {
	const [activeTab, setActiveTab] = useState("tab1");
	const [activeInfoTab, setActiveInfoTab] = useState("tabOne");

	const handleTab1 = () => {
		setActiveTab("tab1");
	};
	const handleTab2 = () => {
		setActiveTab("tab2");
	};

	const handleInfoTabOne = () => {
		setActiveInfoTab("tabOne");
	};
	const handleInfoTabTwo = () => {
		setActiveInfoTab("tabTwo");
	};

	return (
		<div className="carInfo">
			<div className="carInfo__header">
				<div className="left">
					<h3 className="title">Мое авто</h3>
					<ul className="left__tab">
						<li onClick={handleInfoTabOne} className={`item ${activeInfoTab === "tabOne" ? "item-active" : ""}`}>
							Штрафы
						</li>
						<li onClick={handleInfoTabTwo} className={`item ${activeInfoTab === "tabTwo" ? "item-active" : ""}`}>
							Страховка
						</li>
					</ul>
				</div>
				<div className="right">
					<Select.DefaultSelect
						title="Chery Arrizo 5e"
						selectOptions={CarOptions}
						defaultItem="01 а 001 аа"
						blackTheme={true}
						IconArrow={IconDownWhite}
					/>
				</div>
			</div>
			{activeInfoTab === "tabOne" ? (
				<div className="content">
					<div className="content__top">
						<div className="content__tab">
							<div onClick={handleTab1} className={activeTab === "tab1" ? "notPaid" : "disabledTab"}>
								Не оплачено
							</div>
							<div onClick={handleTab2} className={activeTab === "tab2" ? "history" : "disabledTab"}>
								История
							</div>
						</div>
						<div className="content__search">
							<img src={IconSearch} alt="searchIcon" />
							<input type="text" placeholder="Поиск" />
						</div>
					</div>
					{activeTab === "tab1" ? (
						<div className="content__table">
							<table>
								<thead>
									<tr>
										<th>Дата</th>
										<th>Номер постановления</th>
										<th>Статья</th>
										<th>Описание</th>
										<th>Место</th>
										<th>Сумма</th>
										<th>Срок</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="dates">20.07.2022</td>
										<td className="normalText">PTSH 190710365547</td>
										<td className="normalText">138 - 1Q </td>
										<td className="normalText">Piyodalar yo'lining harakat qismini belgilanmagan joyda kesib o'tish</td>
										<td className="normalText">г. Ташкент, р. Яккасарайский, ул. Бобура</td>
										<td className="boldText">545 000 UZS</td>
										<td className="boldText">2 дня 3 часа</td>
										<td>
											<button className="btnPaid" type="button">
												Оплатить
											</button>
										</td>
									</tr>
									<tr>
										<td className="dates">20.07.2022</td>
										<td className="normalText">PTSH 190710365547</td>
										<td className="normalText">138 - 1Q </td>
										<td className="normalText">Piyodalar yo'lining harakat qismini belgilanmagan joyda kesib o'tish</td>
										<td className="normalText">г. Ташкент, р. Яккасарайский, ул. Бобура</td>
										<td className="boldText">545 000 UZS</td>
										<td className="boldText">2 дня 3 часа</td>
										<td>
											<button className="btnPaid" type="button">
												Оплатить
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					) : (
						<div className="content__table">
							<table>
								<thead>
									<tr>
										<th>Дата</th>
										<th>Номер постановления</th>
										<th>Статья</th>
										<th>Описание</th>
										<th>Место</th>
										<th>Сумма</th>
										<th>Дата оплаты</th>
										<th>Статус</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="dates">20.07.2022</td>
										<td className="normalText">PTSH 190710365547</td>
										<td className="normalText">138 - 1Q </td>
										<td className="normalText">Piyodalar yo'lining harakat qismini belgilanmagan joyda kesib o'tish</td>
										<td className="normalText">г. Ташкент, р. Яккасарайский, ул. Бобура</td>
										<td className="boldText">545 000 UZS</td>
										<td className="dates">20.07.2022 12:45</td>
										<td className="status">
											<img className="IconChecked" src={IconChecked} alt="iconChecked" />
											Оплачено
										</td>
									</tr>
									<tr>
										<td className="dates">20.07.2022</td>
										<td className="normalText">PTSH 190710365547</td>
										<td className="normalText">138 - 1Q </td>
										<td className="normalText">Piyodalar yo'lining harakat qismini belgilanmagan joyda kesib o'tish</td>
										<td className="normalText">г. Ташкент, р. Яккасарайский, ул. Бобура</td>
										<td className="boldText">545 000 UZS</td>
										<td className="dates">20.07.2022 12:45</td>
										<td className="status">
											<img className="IconChecked" src={IconChecked} alt="iconChecked" />
											Оплачено
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}
					<div className="content__pagination">
						<Pagination
							count={6}
							renderItem={(item) => (
								<PaginationItem
									components={{
										next: (props) => <img {...props} src={arrowRight} alt="iconleft" />,
										previous: (props) => <img {...props} src={arrowLeft} alt="iconleft" />
									}}
									{...item}
								/>
							)}
						/>
					</div>
				</div>
			) : (
				<div className="insurance">
					<div className="insurance__card">
						<div className="logoInsurance">
							<img src={IconInsurance} alt="iconInsurance" />
						</div>
						<div className="texts">
							<h3 className="ins-name">Осаго</h3>
							<p className="car-name">Tesla Model S</p>
							<p className="ins-number">№545878WER789998</p>
							<div className="deadline">
								<div className="ins-deadline">
									<p className="date">До 02 марта 2023 г</p>
									<BorderLinearProgress variant="determinate" value={30} />
								</div>
								<button type="button" className="extend">
									Продлить
								</button>
							</div>
						</div>
					</div>
					<div className="insurance__card">
						<div className="logoInsurance">
							<img src={IconInsurance} alt="iconInsurance" />
						</div>
						<div className="texts">
							<h3 className="ins-name">Осаго</h3>
							<p className="car-name">Tesla Model S</p>
							<p className="ins-number">№545878WER789998</p>
							<div className="deadline">
								<div className="ins-deadline">
									<p className="date">До 02 марта 2023 г</p>
									<BorderLinearProgress variant="determinate" value={30} />
								</div>
								<button type="button" className="extend">
									Продлить
								</button>
							</div>
						</div>
					</div>
					<div className="insurance__btn">
						<Button.Default
							title="Оформить страховку"
							buttonType="button"
							size="large"
							type="default"
							hasIconArrow={false}
							rounded={true}
							borderType="half"
							iconBefore="plus"
							svgColor="white"
						/>
						<Button.Default
							title="Добавить страховку"
							buttonType="button"
							size="large"
							type="light"
							hasIconArrow={false}
							rounded={true}
							borderType="half"
							iconBefore="plus"
							svgColor="black"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default CarInfo;
