import { SimpleAnimatedModal } from "./SimpleAnimatedModal";
import { AnimatedFullModal } from "./AnimatedFullModal";
import { BlackFullModal } from "./BlackFullModal";
import { PurchaseAnimatedModal } from "./PurchaseAnimatedModal";

const PopupComponents = {
    SimpleAnimatedModal,
    AnimatedFullModal,
    BlackFullModal,
    PurchaseAnimatedModal
};

export default PopupComponents;
