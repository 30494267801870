import React from "react";
import { Button } from "components";
import "./ads.scss";
import AdsImage from "assets/images/profile/adsImage.jpg";

const Ads = () => {
	return (
		<div className="ads" style={{ backgroundImage: `url(${AdsImage})` }}>
			<div className="ads__content">
				<h3 className="title">Большие скидки на все модели Tesla</h3>
				<span className="subtitle">Ознакомьтесь с условиям акции</span>
				<Button.Link 
                    url="" 
                    title="Подробнее" 
                    type="default" 
                    size="large" 
                    rounded={true} 
                    hasIcon={false} 
                    hasIconArrow={true}
                    iconAfter="arrowbig-right" 
                    borderType="half" 
                    svgColor="white"
                />
			</div>
		</div>
	);
};

export default Ads;
