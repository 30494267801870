import React from "react";
import "./lastViewCard.scss";

const LastViewCard = ({ CardImage, title, price }) => {
	return (
		<div className="viewCard">
			<img src={CardImage} alt="cardImage" />
			<h3>{title}</h3>
			<p>{price}</p>
		</div>
	);
};

export default LastViewCard;
