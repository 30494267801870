import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useLocation } from "react-router-dom";
import { NetworkError } from "components";
import Test from "./Test";

const Layout = props => {
	const { pathname } = useLocation();
	const { children } = props;

	return (
		<>
			<NetworkError />
			<div className={pathname === "/" ? "page-wrapper1" : "page-wrapper2"}>
				<Test />
				<Header />
				<div className="page-content">{children}</div>
				{pathname !== '/configuration' && <Footer />}
			</div>
		</>
	);
};

export default Layout;
