import { makeAutoObservable } from 'mobx';
import axios from "axios";

export default class ContentGuideStore {
  constructor(rootStore) {
    this.companyData = null;

    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getCompany = () => {
    this.rootStore.setLoading(true);
    return axios.post('/b/ap/stream/ph&company', { "is_web": "Y", "lang_code": "ru", "filial_id": "100" })
      .then(({ data }) => {
        this.setCompanyData(data);
        return data;
      });
  };

  setCompanyData = (data = []) => {
    this.companyData = data;
  };
}
