import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdClose } from "react-icons/md";

import "./multiSelect.scss";

const MultiSelect = ({ title, dropList, list, setList }) => {
	const [drop, setDrop] = useState(false);
	const handleChange = () => {
		setDrop(!drop);
	};

	const handAddList = (e, text) => {
		if (e.target.checked) {
			setList([...list, text]);
		} else {
			setList(list.filter((item) => item.id !== text.id));
		}
	};

	const handleRemove = (text) => {
		let indexText = list.indexOf(text);
		list.splice(indexText, 1);
		setList([...list]);
	};
	return (
		<div>
			<div className="card">
				<div className="card__box" onClick={handleChange}>
					<p className="card__text">{title}</p>
					{drop ? <IoIosArrowUp className="card__icon" /> : <IoIosArrowDown className="card__icon" />}
				</div>
				<div className={`${drop ? "card__active" : "card__menu"}`}>
					{dropList && dropList.map((text) => (
						<label htmlFor={text.id} key={text.id} className="card__active-item">
							<input
								type="checkbox"
								checked={list && !!list?.find((item) => item.id === text.id)}
								name={text.id}
								id={text.id}
								onChange={(e) => handAddList(e, text)}
							/>
							{text.item}
						</label>
					))}
				</div>

				<div className="card__menu-container">
					{list && list.map((text) => (
						<div className="card__item" key={text.id}>
							{text.item}
							<MdClose className="card__close" onClick={() => handleRemove(text)} />
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default MultiSelect;
