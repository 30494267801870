import { BehaviorSubject } from 'rxjs';

import { storage } from "services";
const currentUserSubject = new BehaviorSubject(JSON.parse(storage.get('currentUser')));

export const authenticationService = {
    validate,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function validate(user) {
    storage.set('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
}

function logout() {
    // remove user from local storage to log user out
    storage.remove('currentUser');
    currentUserSubject.next(null);
}